module services {
    export module master {
        export class requirementGroupService implements interfaces.master.IRequirementGroupService {

            static $inject = ["$resource", "ENV","$http","$timeout", "generalService"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig,private $http: ng.IHttpService,private $timeout: ng.ITimeoutService,public generalService: interfaces.applicationcore.IGeneralService) {
            }
            
            getList(): ng.resource.IResourceClass<interfaces.master.IRequirementGroupDisplay> {

                return this.$resource<interfaces.master.IRequirementGroupDisplay>(this.ENV.DSP_URL + "RequirementGroup/GetList", {
                });
            }

            getListExcel(): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'RequirementGroup/GetListExcel?';

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'Products.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            getDetail(requirementGroupId: number): ng.resource.IResourceClass<interfaces.master.IRequirementGroupViewModel> {
                return this.$resource<interfaces.master.IRequirementGroupViewModel>(this.ENV.DSP_URL + "RequirementGroup/GetDetail", {
                    requirementGroupId: requirementGroupId
                }, {
                    query: {
                        method: 'GET',
                        isArray: false,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }
                });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RequirementGroup/Save");
            }

            getFilterTypes(): ng.resource.IResourceClass<interfaces.master.IFilterType> {
                return this.$resource<interfaces.master.IFilterType>(this.ENV.DSP_URL + "RequirementGroup/GetFilterTypes", {
                });
            }

            getFilterDropdownList(TransactionTypeClassificationValueId:number,ownerEntityId: number,type:string, searchText:string): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "RequirementGroup/GetFilterDropdownList", {
                    TransactionTypeClassificationValueId: TransactionTypeClassificationValueId,
                    ownerEntityId: ownerEntityId,
                    type: type,
                    searchText: searchText
                });
            }

            getReportsDropDown(entityId: number ): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "RequirementGroup/GetReportsDropDown", {
                    entityId: entityId
                });
            }

            getFilterListExcel(requirementGroupId: number): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'RequirementGroup/GetFilterListExcel?requirementGroupId='+requirementGroupId;

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'Products.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            uploadFilterListFromExcel(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RequirementGroup/UploadFilterListFromExcel", {});
            }



        }        
    }

    angular.module("app").service("requirementGroupService", services.master.requirementGroupService);
}